<template>
  <Menu bottom-left label="settings" class="w-8 Icons">
    <template v-slot:title>
      <div>
        <icon
          size="s"
          class-name="h-full mx-auto self-center"
          icon-name="bell"
        />
        <span
          v-if="notifications.length"
          class="bg-flame rounded notificationBadge"
        >
          {{ unreadCount > 9 ? "9+" : unreadCount }}</span
        >
      </div>
    </template>
    <template v-slot:dropdownTitle>
      <div class="flex justify-between px-4">
        <div class="pb-4 text-lg font-bold text-darkPurple">
          {{ `Notifications (${notifications.length})` }}
        </div>
        <Menu v-if="notifications.length > 0" top-value="9px" inner-menu>
          <template v-slot:title>
            <span @click.stop>
              <icon size="s" class="-mt-4" icon-name="icon-more" />
            </span>
          </template>
          <div class="px-2 w-36">
            <div
              @click="handleMarkAll"
              class="flex py-1 px-1 h-8 more cursor-pointer"
            >
              <p class="pt-1 text-sm">Mark all as read</p>
            </div>
          </div>
        </Menu>
      </div>
    </template>
    <div style="width: 261px" class="pt-5 px-4">
      <div
        v-for="(notification, index) in notifications.slice(0, 4)"
        class="mb-2 py-3"
        @click.stop
        :class="notification.categoryId ? 'cursor-pointer' : ''"
        :style="'borderBottom: 0.5px dashed #878E99'"
        :key="index"
      >
        <div v-if="index <= 5">
          <p class="capitalize text-darkPurple">{{ notification.title }}</p>
          <p class="text-jet text-sm wrapText">
            {{ notification.description }}
          </p>
          <p class="text-sm text-jet opacity-80" v-if="notification.categoryId">
            Click to view
          </p>
        </div>
      </div>
      <h1
        class="text-center text-jet opacity-80"
        v-if="notifications.length === 0"
      >
        No Unread Notification
      </h1>
      <h1
        @click="handleSeeAll"
        class="text-flame my-4 text-center text-sm cursor-pointer"
      >
        View all notifications
      </h1>
    </div>
  </Menu>
</template>

<script>
import io from "socket.io-client";
import Menu from "@/components/Menu";
import Icon from "@/components/Icon";

export default {
  components: {
    Icon,
    Menu,
  },
  props: {
    appPage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      notifications: [],
      unreadCount: "",
      notificationsLength: 0,
    };
  },
  methods: {
    handleSeeAll() {
      if (this.appPage === "ESS" && this.$route.name !== "EssNotification") {
        this.$router.push({ name: "EssNotification" });
      } else if (
        this.appPage === "Admin" &&
        this.$route.name !== "Notification"
      ) {
        this.$router.push({ name: "Notification" });
      }
    },
    handleMarkAll() {
      const payload = {
        userId: this.$AuthUser.id,
        ids: [],
        app: "humanar",
      };

      this.notifications.forEach((notification) => {
        // eslint-disable-next-line no-underscore-dangle
        payload.ids.push(notification._id);
      });

      this.$_markNotificationsAsRead(payload)
        .then(() => {
          this.getAllNotifications("");
          this.$toasted.success("All notification marked as read", {
            duration: 3000,
          });
        })
        .catch(() =>
          this.$toasted.error("An error occurred", { duration: 2000 })
        );
    },

    getAllNotifications(params) {
      this.$_getNotifications(params).then((response) => {
        this.notificationsLength = response.data.notifications.length;
      });
    },

    pollData() {
      const getUser = JSON.parse(localStorage.getItem("linchpin"));
      const userId = getUser ? getUser.user.id : "";
      const socket = io(`${process.env.VUE_APP_NOTIFICATION}`, {
        query: {
          userId,
          app: "humanar",
        },
      });
      socket.on("getNotifications", (payload) => {
        this.notifications = payload.notifications;
        this.unreadCount = payload.unreadCount;
      });
      socket.on("getUserNotifications", (payload) => {
        this.notifications = payload.notifications;
        this.unreadCount = payload.unreadCount;
      });
    },
  },

  mounted() {
    this.pollData();
    this.getAllNotifications("");
  },
};
</script>

<style>
.settingContainer {
  padding: 10px 25px;
  background: #f7f7ff;
  border-radius: 5px;
}
.notificationBadge {
  width: 19.2px;
  height: 19.2px;
  left: 60%;
  border-radius: 50%;
  top: 43.61%;
  text-align: center;
  font-size: 12px;
  color: white;
  font-weight: bolder;
  position: absolute;
}
.wrapText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
